import React, { useEffect, useState } from "react";
import { AllTextObject } from "../@types/language";
import Header from "../components/organisms/Header";
import AdminMenu from "../components/organisms/AdminMenu";
import DashboardInsight from "../components/molecules/DashboardInsight";
import OffertesMenu from "../components/molecules/OffertesMenu";
import { OfferteData, UserRole } from "../@types/user";
import { testRowData } from "../data/dummyData";
import { checkRole as checkRoleApi } from "../services/api/api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";

type Props = { allText: AllTextObject };

function AdminLandingScreen({ allText }: Props) {
  const [offerteData, setOfferteData] = useState<OfferteData[]>(testRowData);

  //START LOGIN MODULE
  const dispatch = useDispatch();
  const isAdmin = useSelector(
    (state: RootState) => state.configurator.checkRole
  );

  useEffect(() => {
    if (isAdmin !== UserRole.owner && isAdmin !== undefined) {
      window.location.href = "/";
    }
  }, [isAdmin]);
  //END LOGIN MODULE

  useEffect(() => {
    checkRoleApi(dispatch);
    //TODO DAAN: get offerte data from database here (only the offertes from the last 7 days)
    //Het json array dat ontvangen moet worden ziet eruit zoals het OfferteData type
    //in de backend filteren op de datum van de offerte en enkel deze doorsturen
  }, []);

  return (
    <>
      <Header allText={allText} />
      {isAdmin === UserRole.owner && (
        <main>
          <AdminMenu />
          <section className="dashboard-page">
            <div className="admin-landing-insights-container">
              <DashboardInsight
                number={17}
                title="offertes verstuurd"
                subtitle="in de laatste 7 dagen"
              />
              <DashboardInsight
                number={8}
                title="bestellingen"
                subtitle="in de laatste 7 dagen"
              />
            </div>
            <OffertesMenu offerteData={offerteData} />
          </section>
        </main>
      )}
    </>
  );
}

export default AdminLandingScreen;
