import {
  ConfiguratorState,
  FilterWidthInfo,
  GlassThiknesses,
  Prices,
  StepInfo,
  StepItemInfoList,
  StepOpenedInfo,
  Steps,
  WidthStripValues,
} from "../@types/configurator";
import {
  PayloadAction,
  createAction,
  createSlice,
  current,
} from "@reduxjs/toolkit";
import {
  defaultStepInfoList,
  defaultStepItemInfoOverview,
} from "../data/configurator";
import { ProductData } from "../@types/product";
import { store } from "./store";
import { updateStepInfoList } from "../utils/stepConfigurator";
import { UserRole } from "../@types/user";
import fallbackFilterWidthInfo from "../components/aquariumComponents/jsonFiles/filterWidthInfo.json";
import fallbackGlassThiknesses from "../components/aquariumComponents/jsonFiles/rimless.json";
import fallbackPrices from "../components/aquariumComponents/jsonFiles/prices.json";
import fallbackWidthStripValues from "../components/aquariumComponents/jsonFiles/widthStripvalues.json";

const initialState: ConfiguratorState = {
  openTexturesModal: false,
  openFilterLocationModal: false,
  showOverviewScreen: false,
  stepInfoList: defaultStepInfoList,
  stepItemInfoOverview: defaultStepItemInfoOverview,
  openStep: null,
  openProductInfoModal: null,
  URLAquariumLoaded: false,
  aquariumSnapshot: null,
  showHelpModal: false,
  checkRole: undefined,
  filterWidthInfo: fallbackFilterWidthInfo,
  glassThiknesses: fallbackGlassThiknesses,
  prices: fallbackPrices,
  widthStripValues: fallbackWidthStripValues,
};
export const resetConfigurator = createAction("REVERT_ALL");

const configuratorSlice = createSlice({
  name: "configurator",
  initialState,
  reducers: {
    setOpenTexturesModal: (state, action: PayloadAction<boolean>) => {
      state.openTexturesModal = action.payload;
    },
    setOpenFilterLocationModal: (state, action: PayloadAction<boolean>) => {
      state.openFilterLocationModal = action.payload;
    },
    setShowOverviewScreen: (state, action: PayloadAction<boolean>) => {
      state.showOverviewScreen = action.payload;
    },
    setStepInfoList: (state, action: PayloadAction<StepInfo[]>) => {
      state.stepInfoList = action.payload;
    },
    setStepItemInfoOverview: (
      state,
      action: PayloadAction<StepItemInfoList>
    ) => {
      state.stepItemInfoOverview = action.payload;
    },
    setOpenStep: (state, action: PayloadAction<Steps | null>) => {
      state.openStep = action.payload;
    },
    setOpenProductInfoModal: (
      state,
      action: PayloadAction<ProductData | null>
    ) => {
      state.openProductInfoModal = action.payload;
    },
    setOpenHelpModal: (state, action: PayloadAction<boolean>) => {
      state.showHelpModal = action.payload;
    },
    setURLAquariumLoaded: (state, action: PayloadAction<boolean>) => {
      state.URLAquariumLoaded = action.payload;
    },
    setAquariumSnapshot: (state, action: PayloadAction<string | null>) => {
      state.aquariumSnapshot = action.payload;
    },
    setCheckRole: (
      state,
      action: PayloadAction<UserRole | null | undefined>
    ) => {
      state.checkRole = action.payload;
    },
    setFilterWidthInfo: (state, action: PayloadAction<FilterWidthInfo>) => {
      state.filterWidthInfo = action.payload;
    },
    setGlassThiknesses: (state, action: PayloadAction<GlassThiknesses>) => {
      state.glassThiknesses = action.payload;
    },
    setPrices: (state, action: PayloadAction<Prices>) => {
      state.prices = action.payload;
    },
    setWidthStripValues: (state, action: PayloadAction<WidthStripValues>) => {
      state.widthStripValues = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(resetConfigurator, () => initialState),
});

export const {
  setOpenTexturesModal,
  setOpenFilterLocationModal,
  setShowOverviewScreen,
  setStepInfoList,
  setStepItemInfoOverview,
  setOpenStep,
  setOpenProductInfoModal,
  setURLAquariumLoaded,
  setAquariumSnapshot,
  setOpenHelpModal,
  setCheckRole,
  setFilterWidthInfo,
  setGlassThiknesses,
  setPrices,
  setWidthStripValues,
} = configuratorSlice.actions;

export default configuratorSlice.reducer;
