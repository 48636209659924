import { Dispatch } from "react";
import { UnknownAction } from "redux";
import {
  Aquarium,
  AquariumDimensions,
  AquariumType,
  BoreHole,
  CoverPanelsColor,
  DeliveryOption,
  DoorType,
  ElectraRuimte,
  FilterLocation,
  FilterOptions,
  FullGlassCoverPanels,
  FurnitureType,
  PvcPipeworkPackage,
  SideInfo,
  SiliconColor,
  TurtleIsland,
  WaterType,
  WindowPaintOption,
} from "../@types/aquarium";
import {
  changeWidth,
  changeDepth,
  changeHeight,
} from "../components/aquariumComponents/aquarium";
import { amountStripsFullGlass } from "../components/aquariumComponents/fullGlass";
import { amountStripLightCover } from "../components/aquariumComponents/lightCover";
import {
  changeAquariumType,
  changeBioCoverPanels,
  changeBoreHole,
  changeClosetElectraSwitchVisibility,
  changeClosetType,
  changeCoverPanels,
  changeCoverPlateTextures,
  changeFilter,
  changeFilterLocation,
  changeFurnitureTexture,
  changeGrindListVisbility,
  changeLightCover,
  changeLowerClosetHeight,
  changeOsmoseCompartiment,
  changePaintColor,
  changeUpperClosetHeight,
  makeAquariumSeeThrough,
  makeSideOptiWhite,
  paintSide,
  setBetonPlex,
  setDoorType,
  setElectraSpace,
  setFullGlassPanels,
  setRearWall,
  setTurtleIsland,
} from "../components/aquariumComponents/tank";
import { amountStripsTurtle } from "../components/aquariumComponents/turtle";
import { defaultAquarium, defaultDrawingAquarium } from "./defaultAquarium";
import {
  setAquarium,
  setFilterLocation,
  setLightCover as setLightCoverRedux,
} from "../redux/aquariumSlice";
import { setCoverPanels as setCoverPanelsRedux } from "../redux/aquariumSlice";
import { DecorTexture } from "../@types/textures";
import { isInStringEnum } from "./configurator";
import { setURLAquariumLoaded } from "../redux/configuratorSlice";
import { EnumType } from "typescript";
import { testProductData } from "../data/dummyData";
import { ProductData } from "../@types/product";
import { changeWaterProofPlateHeight } from "../components/aquariumComponents/closetOptions";

export const checkDimensions = (dimensions: AquariumDimensions) => {
  const validWidth =
    dimensions.width < 50 || dimensions.width > 360 || !changeWidth(dimensions)
      ? false
      : true;

  const validDepth =
    dimensions.depth > dimensions.width ||
    dimensions.depth < 30 ||
    dimensions.depth > 110 ||
    !changeDepth(dimensions)
      ? false
      : true;

  const validHeight =
    dimensions.height < 30 ||
    dimensions.height > 115 ||
    !changeHeight(dimensions)
      ? false
      : true;

  return {
    isValidWidth: validWidth,
    isValidDepth: validDepth,
    isValidHeight: validHeight,
  };
};

export const calcAquariumVolume = (dimensions: AquariumDimensions) => {
  return (dimensions.width * dimensions.depth * dimensions.height) / 1000;
};

export const getAmountWidthStripsAquarium = (type: AquariumType) => {
  switch (type) {
    case AquariumType.fullGlass:
      return amountStripsFullGlass();
    case AquariumType.lightCover:
      return amountStripLightCover();
    case AquariumType.turtle:
      return amountStripsTurtle();

    default:
      return 0;
  }
};

function updateTypeOptions(dispatch: Dispatch<UnknownAction>) {
  // Vreemd dat deze omgekeerde vergelijking werkt, moet nog eens naar gekeken worden
  if (window.location.href.includes("coverPanels=true")) {
    dispatch(setCoverPanelsRedux(defaultAquarium.coverPanels));
  }
  if (window.location.href.includes("lightCover=true")) {
    dispatch(setLightCoverRedux(defaultAquarium.lightCover));
  }
}

const updateSide = (side: SideInfo) => {
  paintSide(side.sideName, side.colorPainted);
  makeSideOptiWhite(side.sideName, side.optiWhite);
};

let prevAquarium: Aquarium = defaultAquarium;
export const updateDrawing = (
  aquarium: Aquarium,
  dispatch: Dispatch<UnknownAction>
) => {
  console.log("updateDrawing");

  //DIMENSIONS STEP
  changeWidth(aquarium.aquariumDimensions);
  changeDepth(aquarium.aquariumDimensions);
  changeHeight(aquarium.aquariumDimensions);

  //TODO: hier nog het verandere van de siliconenkleur toevoegen

  //AQUARIUM TYPE STEP
  if (aquarium.aquariumType !== null) {
    changeAquariumType(aquarium.aquariumType);
  } else {
    changeAquariumType(AquariumType.rimless);
  }
  if (aquarium.aquariumType !== prevAquarium.aquariumType) {
    updateTypeOptions(dispatch);
  }
  changeCoverPanels(aquarium.coverPanels);
  changeCoverPlateTextures(aquarium.coverPanelsColor);
  changeLightCover(aquarium.lightCover);
  if (aquarium.fullGlassCoverPanels) {
    setFullGlassPanels(aquarium.fullGlassCoverPanels);
  }
  if (aquarium.turtleIsland) {
    setTurtleIsland(aquarium.turtleIsland);
  }
  changeGrindListVisbility(aquarium.grindlist);
  if (
    aquarium.aquariumTexture !== null &&
    aquarium.aquariumTexture !== prevAquarium.aquariumTexture
  ) {
    changeFurnitureTexture(aquarium.aquariumTexture);
  } else if (
    aquarium.aquariumTexture === null &&
    aquarium.aquariumTexture !== prevAquarium.aquariumTexture
  ) {
    changeFurnitureTexture(defaultAquarium.aquariumTexture);
  }

  // WINDOW STEP
  aquarium.windowPaintColor === WindowPaintOption.black
    ? changePaintColor(0x000000)
    : changePaintColor(0x3d51ff);
  updateSide(aquarium.aquariumSides.front);
  updateSide(aquarium.aquariumSides.back);
  updateSide(aquarium.aquariumSides.left);
  updateSide(aquarium.aquariumSides.right);

  //FILTER STEP
  if (
    aquarium.filter.filterType !== prevAquarium.filter.filterType ||
    (aquarium.filter.filterType === FilterOptions.external &&
      !aquarium.filter.externalDryroom)
  ) {
    changeFilterLocation(FilterLocation.placeholder);
  }
  if (aquarium.filter.filterType) {
    changeFilter(aquarium.filter.filterType);
  } else {
    changeFilter(FilterOptions.none);
  }
  if (aquarium.filter.filterLocation !== null) {
    changeFilterLocation(aquarium.filter.filterLocation);
  } else {
    changeFilterLocation(FilterLocation.placeholder);
  }
  changeBioCoverPanels(aquarium.filter.bioCoverPanels);
  if (aquarium.filter.filterType === FilterOptions.sump) {
    changeOsmoseCompartiment(aquarium.filter.osmoseCompartiment);
  }
  changeBoreHole(BoreHole.left, aquarium.filter.boreholeLeft);
  changeBoreHole(BoreHole.right, aquarium.filter.boreholeRight);

  //FURNITURE STEP
  if (aquarium.furniture.furnitureType) {
    changeClosetType(aquarium.furniture.furnitureType);
  } else {
    changeClosetType(FurnitureType.none);
  }
  if (
    aquarium.furniture.furnitureType !== null ||
    aquarium.furniture.furnitureType !== FurnitureType.frameOnly
  ) {
    changeLowerClosetHeight(aquarium.furniture.dimensions.baseHeight);
    changeUpperClosetHeight(aquarium.furniture.dimensions.topHeight);
  } else {
    changeLowerClosetHeight(0);
  }
  setBetonPlex(aquarium.furniture.betonPlex);
  setRearWall(aquarium.furniture.backWall34);
  makeAquariumSeeThrough(aquarium.furniture.seeThrough);
  if (aquarium.furniture.electraRuimte !== null) {
    setElectraSpace(aquarium.furniture.electraRuimte);
  } else {
    setElectraSpace(ElectraRuimte.none);
  }
  if (aquarium.furniture.doorType) {
    setDoorType(aquarium.furniture.doorType);
  }
  changeClosetElectraSwitchVisibility(aquarium.furniture.aquaswitch);
  if (!aquarium.extra.waterproofPlate) {
    // TODO DAAN: deze functie lijkt precies geen 0 te ontvangen, de plaat blijft op de tekening staan
    changeWaterProofPlateHeight(0);
  }

  //zet de huidige aquariumconfiguratie nu ter vergelijking om de volgende aanpassingen mee te vergelijken
  prevAquarium = aquarium;
};

const checkBoolean = (value: any, defaultValue: boolean) => {
  return value === "true" ? true : value === "false" ? false : defaultValue;
};
export const checkEnum = (value: any, enumType: any, defaultValue: any) => {
  return isInStringEnum(value, enumType) && value !== null
    ? (value as EnumType)
    : defaultValue;
};

const checkProduct = (ids: string[]) => {
  let productsData: ProductData[] = [];
  for (let i = 0; i < ids.length; i++) {
    testProductData.filter((product) => {
      if (product.id === ids[i]) {
        productsData.push(product);
      }
    });
  }
  return productsData;
};

export const setURLAquarium = (
  params: URLSearchParams,
  dispatch: Dispatch<UnknownAction>
) => {
  const URLAquarium: Aquarium = {
    aquariumDimensions: {
      width:
        Number(params.get("length")) ||
        defaultAquarium.aquariumDimensions.width,
      depth:
        Number(params.get("width")) || defaultAquarium.aquariumDimensions.depth,
      height:
        Number(params.get("height")) ||
        defaultAquarium.aquariumDimensions.height,
    },
    sharpenGlass: checkBoolean(
      params.get("sharpenGlass"),
      defaultAquarium.sharpenGlass
    ),
    waterType: checkEnum(
      params.get("waterType"),
      WaterType,
      defaultAquarium.waterType
    ),
    aquariumType: checkEnum(
      params.get("aquariumType"),
      AquariumType,
      defaultAquarium.aquariumType
    ),
    aquariumTexture: checkEnum(
      params.get("aquariumTexture"),
      DecorTexture,
      defaultAquarium.aquariumTexture
    ),
    coverPanels: checkBoolean(
      params.get("coverPanels"),
      defaultAquarium.coverPanels
    ),
    coverPanelsColor: checkEnum(
      params.get("coverPanelsColor"),
      CoverPanelsColor,
      defaultAquarium.coverPanelsColor
    ),
    fullGlassCoverPanels: checkEnum(
      params.get("fullGlassCoverPanels"),
      FullGlassCoverPanels,
      defaultAquarium.fullGlassCoverPanels
    ),
    turtleIsland: checkEnum(
      params.get("turtleIsland"),
      TurtleIsland,
      defaultAquarium.turtleIsland
    ),
    lightCover: checkBoolean(
      params.get("lightCover"),
      defaultAquarium.lightCover
    ),
    grindlist: checkBoolean(params.get("grindlist"), defaultAquarium.grindlist),
    windowPaintColor: checkEnum(
      params.get("windowPaintColor"),
      WindowPaintOption,
      defaultAquarium.windowPaintColor
    ),
    aquariumSides: {
      left: {
        sideName: defaultAquarium.aquariumSides.left.sideName,
        colorPainted: checkBoolean(
          params.get("sideLeftPainted"),
          defaultAquarium.aquariumSides.left.colorPainted
        ),
        optiWhite: checkBoolean(
          params.get("sideLeftOptiWhite"),
          defaultAquarium.aquariumSides.left.optiWhite
        ),
      },
      right: {
        sideName: defaultAquarium.aquariumSides.right.sideName,
        colorPainted: checkBoolean(
          params.get("sideRightPainted"),
          defaultAquarium.aquariumSides.right.colorPainted
        ),
        optiWhite: checkBoolean(
          params.get("sideRightOptiWhite"),
          defaultAquarium.aquariumSides.right.optiWhite
        ),
      },
      front: {
        sideName: defaultAquarium.aquariumSides.front.sideName,
        colorPainted: defaultAquarium.aquariumSides.front.colorPainted,
        optiWhite: checkBoolean(
          params.get("sideFrontOptiWhite"),
          defaultAquarium.aquariumSides.front.optiWhite
        ),
      },
      back: {
        sideName: defaultAquarium.aquariumSides.back.sideName,
        colorPainted: checkBoolean(
          params.get("sideBackPainted"),
          defaultAquarium.aquariumSides.back.colorPainted
        ),
        optiWhite: checkBoolean(
          params.get("sideBackOptiWhite"),
          defaultAquarium.aquariumSides.back.optiWhite
        ),
      },
    },
    filter: {
      filterType: checkEnum(
        params.get("filterType"),
        FilterOptions,
        defaultAquarium.filter.filterType
      ),
      externalDryroom: checkBoolean(
        params.get("externalDryroom"),
        defaultAquarium.filter.externalDryroom
      ),
      filterLocation: checkEnum(
        params.get("filterLocation"),
        FilterLocation,
        defaultAquarium.filter.filterLocation
      ),
      bioCoverPanels: checkBoolean(
        params.get("bioCoverPanels"),
        defaultAquarium.filter.bioCoverPanels
      ),
      osmoseCompartiment: checkBoolean(
        params.get("osmoseCompartiment"),
        defaultAquarium.filter.osmoseCompartiment
      ),
      boreholeLeft: checkBoolean(
        params.get("boreholeLeft"),
        defaultAquarium.filter.boreholeLeft
      ),
      boreholeRight: checkBoolean(
        params.get("boreholeRight"),
        defaultAquarium.filter.boreholeRight
      ),
    },
    furniture: {
      furnitureType: checkEnum(
        params.get("furnitureType"),
        FurnitureType,
        defaultAquarium.furniture.furnitureType
      ),
      dimensions: {
        baseHeight:
          Number(params.get("baseHeight")) ||
          defaultAquarium.furniture.dimensions.baseHeight,
        topHeight:
          Number(params.get("topHeight")) ||
          defaultAquarium.furniture.dimensions.topHeight,
      },
      betonPlex: checkBoolean(
        params.get("betonPlex"),
        defaultAquarium.furniture.betonPlex
      ),
      seeThrough: checkBoolean(
        params.get("seeThrough"),
        defaultAquarium.furniture.seeThrough
      ),
      backWall34: checkBoolean(
        params.get("backWall34"),
        defaultAquarium.furniture.backWall34
      ),
      electraRuimte: checkEnum(
        params.get("electraRuimte"),
        ElectraRuimte,
        defaultAquarium.furniture.electraRuimte
      ),
      removableSideLeft: checkBoolean(
        params.get("removableSideLeft"),
        defaultAquarium.furniture.removableSideLeft
      ),
      removableSideRight: checkBoolean(
        params.get("removableSideRight"),
        defaultAquarium.furniture.removableSideRight
      ),
      doorType: checkEnum(
        params.get("doorType"),
        DoorType,
        defaultAquarium.furniture.doorType
      ),
      aquaswitch: checkBoolean(
        params.get("aquaswitch"),
        defaultAquarium.furniture.aquaswitch
      ),
    },
    technics: {
      boosterPump: checkProduct(params.getAll("boosterPump")),
      led: checkProduct(params.getAll("led")),
      heating: checkProduct(params.getAll("heating")),
      filter: checkProduct(params.getAll("filter")),
      circulationPump: checkProduct(params.getAll("circulationPump")),
      proteinSkimmer: checkProduct(params.getAll("proteinSkimmer")),
      automaticRefillSystem: checkProduct(
        params.getAll("automaticRefillSystem")
      ),
      co2System: checkProduct(params.getAll("co2System")),
      interior: checkProduct(params.getAll("interior")),
    },
    extra: {
      siliconeColor: checkEnum(
        params.get("siliconeColor"),
        SiliconColor,
        defaultAquarium.extra.siliconeColor
      ),
      waterproofPlate: checkBoolean(
        params.get("waterproofPlate"),
        defaultAquarium.extra.waterproofPlate
      ),
      pvcPipeworkPackage: checkEnum(
        params.get("pvcPipeworkPackage"),
        PvcPipeworkPackage,
        defaultAquarium.extra.pvcPipeworkPackage
      ),
    },
    delivery: checkEnum(
      params.get("delivery"),
      DeliveryOption,
      defaultAquarium.delivery
    ),
  };
  dispatch(setAquarium(URLAquarium));
  dispatch(setURLAquariumLoaded(true));
};
