import { useEffect, useState } from "react";
import { AllTextObject } from "../@types/language";
import Header from "../components/organisms/Header";
import { Title } from "../components/atoms/StyledComponents";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { OfferteData, UserRole } from "../@types/user";
import "../styles/dataGrid.css";
import { ArrowLeft } from "@phosphor-icons/react";
import { testRowData } from "../data/dummyData";
import OffertesMenu from "../components/molecules/OffertesMenu";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { checkRole as checkRoleApi } from "../services/api/api";

type Props = { allText: AllTextObject };

function AdminOfferteScreen({ allText }: Props) {
  const [offerteData, setOfferteData] = useState<OfferteData[]>(testRowData);
  const dispatch = useDispatch();
  const isAdmin = useSelector(
    (state: RootState) => state.configurator.checkRole
  );

  useEffect(() => {
    if (isAdmin !== UserRole.owner && isAdmin !== undefined) {
      window.location.href = "/";
    }
  }, [isAdmin]);

  useEffect(() => {
    checkRoleApi(dispatch);

    //TODO DAAN: get all offerte data from database here
    //Het json array dat ontvangen moet worden ziet eruit zoals het OfferteData type
  }, []);

  return (
    <>
      <Header allText={allText} />
      {isAdmin === UserRole.owner && (
        <main className="dashboard-page">
          <a href="/dashboard" className="dashboard-goback-arrow">
            <ArrowLeft color="var(--primary-700)" size={32} weight="bold" />
          </a>
          <Title color="var(--primary-700)">Offertes</Title>
          <div className="dashboard-page-header">
            {/* <QuickFilters type={DashboardPages.offertes} /> */}
            {/* <AdminSearchBar placeholder="Zoek op offertenr, naam, stad,..." /> */}
          </div>
          <OffertesMenu offerteData={offerteData} />
        </main>
      )}
    </>
  );
}

export default AdminOfferteScreen;
