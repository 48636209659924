import { Plus, Trash } from "@phosphor-icons/react";
import { CaretRight } from "@phosphor-icons/react/dist/ssr";
import React, { useEffect, useMemo, useState } from "react";
import { SubTitle, Text } from "../atoms/StyledComponents";
import { AG_GRID_LOCALE_NL } from "@ag-grid-community/locale";
import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import {
  PricePerUnitOptions,
  ProductCategory,
  ProductData,
} from "../../@types/product";
import { testProductData } from "../../data/dummyData";
import PrimaryButton from "../atoms/PrimaryButton";
import SecondaryButton from "../atoms/SecondaryButton";
import AddProduct from "../molecules/AddProduct";
import { updateProductData } from "../../services/api/api";

type Props = {
  productCategory: ProductCategory;
  categoryName: string;
  products: ProductData[];
};

function AdminProductsMenu({ productCategory, categoryName, products }: Props) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      resizable: false,
      suppressHeaderFilterButton: true,
      suppressMovable: true,
    };
  }, []);
  const [rowData, setRowData] = useState<ProductData[]>(products);
  const [showDeleteModal, setShowDeleteModal] = useState<any | null>(null);
  const [showAddProductModal, setShowAddProductModal] =
    useState<boolean>(false);

  const getProductUnit = (unit: any) => {
    switch (unit) {
      case PricePerUnitOptions.perPiece:
        return "/stuk";
        break;

      case PricePerUnitOptions.perLiter:
        return "/liter";
        break;

      case PricePerUnitOptions.perSquareMeter:
        return `/m\u00B2`;
        break;

      case PricePerUnitOptions.perKilo:
        return "/kilo";
        break;

      default:
        break;
    }
  };

  const PriceField = (p: any) => {
    return (
      <>
        <div className="editable-value-field">
          €{p.value.toFixed(2).replace(".", ",")}
        </div>
        <div className="editable-value-text">
          {getProductUnit(p.data.pricePerUnit)}
        </div>
      </>
    );
  };
  const Partner1PriceField = (p: any) => {
    return (
      <>
        <div className="editable-value-field">{p.value}%</div>
        <div className="editable-value-text">
          <div className="editable-value-extra">
            (€
            {(p.data.price * (1 - p.data.discountReseller1 / 100))
              .toFixed(2)
              .replace(".", ",")}
            )
          </div>
        </div>
      </>
    );
  };
  const Partner2PriceField = (p: any) => {
    return (
      <>
        <div className="editable-value-field">{p.value}%</div>
        <div className="editable-value-text">
          <div className="editable-value-extra">
            (€
            {(p.data.price * (1 - p.data.discountReseller2 / 100))
              .toFixed(2)
              .replace(".", ",")}
            )
          </div>
        </div>
      </>
    );
  };

  const onRowDataChanged = (e: any) => {
    const updateId = e.data.id;
    const newRowData = rowData.map((row: ProductData) =>
      row.id === updateId ? (row = e.data) : row
    );
    const updatedRow = newRowData.filter((row) => row.id === updateId);
    setRowData(newRowData);
    updateProductData(updatedRow[0]);
    //TODO DAAN: sla deze nieuwe gegevens hier op in de database
    //Het json bestand ziet eruit zoals het hele ProductData type
    //filter in de api/backend op id en vervang de aangepaste info
  };

  const deleteRow = (productId: any) => {
    const newRowData = rowData.filter((row) => row.id !== productId);
    setRowData(newRowData);
    setShowDeleteModal(null);
    //TODO DAAN: delete hier het product uit de database
    //Stuur enkel het id door van het te verwijderen item
    //in de backend/api gaat deze die dan uit de database verwijderen
  };

  const colDefs: ColDef<any>[] = [
    { field: "id", flex: 2, suppressHeaderFilterButton: false },
    {
      field: "name",
      headerName: "Naam",
      flex: 3,
      suppressHeaderFilterButton: false,
    },
    { field: "description", headerName: "Beschrijving", flex: 4 },
    {
      field: "price",
      headerName: "Prijs",
      flex: 2,
      editable: true,
      cellRenderer: (p: any) => PriceField(p),
      cellClass: ["products-menu-input-field"],
    },
    {
      field: "discountReseller1",
      headerName: "Prijs verdeler 1",
      flex: 2,
      editable: true,
      cellRenderer: (p: any) => Partner1PriceField(p),
      cellClass: ["products-menu-input-field"],
    },
    {
      field: "discountReseller2",
      headerName: "Prijs verdeler 2",
      flex: 2,
      editable: true,
      cellRenderer: (p: any) => Partner2PriceField(p),
      cellClass: ["products-menu-input-field"],
    },
    { field: "isRecommanded", headerName: "Aanrader", flex: 1, editable: true },
    {
      field: "deleteProductButton",
      headerName: "",
      flex: 1,
      cellRenderer: (p: any) => (
        <Trash
          size={18}
          color="var(--red-500)"
          className="products-menu-delete-icon"
          onClick={() => setShowDeleteModal(p.data)}
        />
      ),
    },
  ];

  return (
    <div className="products-menu-container">
      <div className="products-menu-header">
        <div
          className={
            "products-menu-header-title " +
            (showMenu ? "products-menu-header-title-open" : "")
          }
          onClick={() => setShowMenu(!showMenu)}
        >
          <CaretRight
            color={showMenu ? "var(--primary-700)" : "var(--primary-300)"}
            size={28}
            weight="bold"
            className="products-menu-header-arrow"
            style={{ rotate: showMenu ? "z 90deg" : "z 0deg" }}
          />
          <SubTitle
            color={showMenu ? "var(--primary-700)" : "var(--primary-300)"}
          >
            {categoryName}
          </SubTitle>
        </div>
        <Plus
          color="var(--primary-600)"
          size={28}
          weight="bold"
          className="add-product-button"
          onClick={() => setShowAddProductModal(!showAddProductModal)}
          style={{ rotate: showAddProductModal ? "z 45deg" : "z 0deg" }}
        />
      </div>
      {showAddProductModal && <AddProduct productCategory={productCategory} />}
      {showMenu && (
        <div className="ag-theme-quartz master-grid-container master-grid products-menu-grid">
          {showDeleteModal && (
            <div className="grid-details-container delete-product-modal-container">
              <div className="grid-details-content-container delete-product-modal-content-container">
                <Text color="var(--primary-700)" weight={500}>
                  Weet je zeker dat je {showDeleteModal.name} wilt verwijderen?
                </Text>
                <div className="delete-product-modal-buttons">
                  <PrimaryButton
                    title="verwijder"
                    action={() => deleteRow(showDeleteModal.id)}
                  />
                  <SecondaryButton
                    title="annuleer"
                    action={() => setShowDeleteModal(null)}
                  />
                </div>
              </div>
            </div>
          )}
          <AgGridReact<ProductData>
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={10}
            paginationPageSizeSelector={[10, 20, 50]}
            rowSelection="single"
            suppressMenuHide={true}
            localeText={AG_GRID_LOCALE_NL}
            domLayout="autoHeight"
            suppressHorizontalScroll={false}
            onCellValueChanged={(e) => onRowDataChanged(e)}
          />
        </div>
      )}
    </div>
  );
}

export default AdminProductsMenu;
