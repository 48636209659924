import React, { useEffect, useMemo, useState } from "react";
import Header from "../components/organisms/Header";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../styles/dataGrid.css";
import { AllTextObject, InputLanguageObject } from "../@types/language";
import { ArrowLeft } from "@phosphor-icons/react";
import { Title } from "../components/atoms/StyledComponents";
import { ColDef } from "ag-grid-enterprise";
import textArray from "../data/text/allTexts.json";
import { AG_GRID_LOCALE_NL } from "@ag-grid-community/locale";
import { AgGridReact } from "ag-grid-react";
import { checkRole as checkRoleApi } from "../services/api/api";
import { useDispatch, useSelector } from "react-redux";
import { UserRole } from "../@types/user";
import { RootState } from "../redux/store";

type Props = { allText: AllTextObject };

function AdminTranslationsScreen({ allText }: Props) {
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      resizable: false,
      suppressHeaderFilterButton: true,
      suppressMovable: true,
    };
  }, []);
  const [rowData, setRowData] = useState<InputLanguageObject[]>(textArray);
  const dispatch = useDispatch();
  const isAdmin = useSelector(
    (state: RootState) => state.configurator.checkRole
  );

  useEffect(() => {
    if (isAdmin !== UserRole.owner && isAdmin !== undefined) {
      window.location.href = "/";
    }
  }, [isAdmin]);

  useEffect(() => {
    checkRoleApi(dispatch);
  }, []);

  const colDefs: ColDef<any>[] = [
    { field: "id", flex: 1, suppressHeaderFilterButton: false },
    {
      field: "dutch",
      headerName: "Nederlands",
      flex: 3,
      suppressHeaderFilterButton: false,
      editable: true,
    },
    {
      field: "english",
      headerName: "Engels",
      flex: 3,
      suppressHeaderFilterButton: false,
      editable: true,
    },
    {
      field: "french",
      headerName: "Frans",
      flex: 3,
      suppressHeaderFilterButton: false,
      editable: true,
    },
    {
      field: "german",
      headerName: "Duits",
      flex: 3,
      suppressHeaderFilterButton: false,
      editable: true,
    },
  ];

  function handleTextUpdate(e: any) {
    const updateId = e.data.id;
    const newRowData = rowData.map((row: InputLanguageObject) =>
      row.id === updateId ? (row = e.data) : row
    );
    setRowData(newRowData);
    //TODO DAAN: sla hier de verandering op in de database
    //Het json bestand ziet eruit zoals het hele InputLanguageObject type
    //filter in de api/backend op id en vervang de aangepaste info
  }

  return (
    <>
      <Header allText={allText} />
      {isAdmin === UserRole.owner && (
        <main className="dashboard-page" id="admin-products-page">
          <a href="/dashboard" className="dashboard-goback-arrow">
            <ArrowLeft color="var(--primary-700)" size={32} weight="bold" />
          </a>
          <Title color="var(--primary-700)">Vertalingen</Title>
          <div className="ag-theme-quartz master-grid-container master-grid translations-grid">
            <AgGridReact<InputLanguageObject>
              rowData={rowData}
              columnDefs={colDefs}
              defaultColDef={defaultColDef}
              pagination={true}
              paginationPageSize={50}
              paginationPageSizeSelector={[50, 100, 200]}
              rowSelection="single"
              suppressMenuHide={true}
              localeText={AG_GRID_LOCALE_NL}
              domLayout="autoHeight"
              suppressHorizontalScroll={false}
              onCellValueChanged={(e) => handleTextUpdate(e)}
            />
          </div>
        </main>
      )}
    </>
  );
}

export default AdminTranslationsScreen;
