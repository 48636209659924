import React, { useEffect, useState } from "react";
import TextInput from "../../molecules/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  setAgreedToConditions,
  setUserEmail as setUserEmailRedux,
} from "../../../redux/userSlice";
import * as EmailValidator from "email-validator";
import { SmallText } from "../../atoms/StyledComponents";
import PrimaryButton from "../../atoms/PrimaryButton";
import { AllTextObject } from "../../../@types/language";

type Props = { allText: AllTextObject };

function UserDisclaimer({ allText }: Props) {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [userEmail, setUserEmail] = useState<string | null>(user.email);
  const [validUserEmail, setValidUserEmail] = useState<boolean | null>(null);
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);

  useEffect(() => {
    if (userEmail) {
      dispatch(setUserEmailRedux(userEmail));
    }
  }, [userEmail]);

  useEffect(() => {
    setUserEmail(user.email);
    setValidUserEmail(userEmail ? EmailValidator.validate(userEmail) : null);
    // setAgreedToTerms(user.agreedToConditions);
  }, [user]);

  const checkValidEmail = () => {
    setValidUserEmail(userEmail ? EmailValidator.validate(userEmail) : null);
    if (userEmail) {
      dispatch(setUserEmailRedux(userEmail));
    }
  };

  const [showScreen, setShowScreen] = useState<boolean>(true);
  const handleOpenConfigurator = () => {
    dispatch(setAgreedToConditions(agreedToTerms));
    setShowScreen(false);
  };

  return (
    <div
      className="user-disclaimer-container"
      style={{ display: showScreen ? "flex" : "none" }}
    >
      <div className="user-disclaimer-content-container">
        <TextInput
          title={allText.tid_hea_06}
          value={userEmail ? userEmail : ""}
          setValue={setUserEmail}
          onBlur={() => checkValidEmail()}
          showError={validUserEmail === false ? true : false}
          errorMessage={allText.tid_cud_01}
        />
        <label
          htmlFor="user-agrees-to-terms-input"
          className={"window-paint-side-option user-agrees-to-terms-input"}
        >
          {allText.tid_cud_02}{" "}
          <a
            href="https://www.aqualife-aquarium.com/algemene-voorwaarden"
            target="_blank"
          >
            {allText.tid_cud_03}
          </a>{" "}
          {allText.tid_cud_04}
          <input
            checked={agreedToTerms}
            type="checkbox"
            id="user-agrees-to-terms-input"
            onChange={() => setAgreedToTerms(!agreedToTerms)}
          />
          <span className="checkmark"></span>
        </label>
        <PrimaryButton
          title={allText.tid_cud_05}
          action={() => handleOpenConfigurator()}
          disabled={!validUserEmail || !agreedToTerms ? true : false}
        />
      </div>
    </div>
  );
}

export default UserDisclaimer;
