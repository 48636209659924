import { useEffect, useState } from "react";
import { AllTextObject } from "../@types/language";
import { ProductCategory, ProductData } from "../@types/product";
import { testProductData } from "../data/dummyData";
import Header from "../components/organisms/Header";
import { ArrowLeft } from "@phosphor-icons/react";
import { Title } from "../components/atoms/StyledComponents";
import AdminProductsMenu from "../components/organisms/AdminProductsMenu";
import { getProductDataFromDatabase } from "../services/api/api";
import { checkRole as checkRoleApi } from "../services/api/api";
import { useDispatch, useSelector } from "react-redux";
import { UserRole } from "../@types/user";
import { RootState } from "../redux/store";

type Props = { allText: AllTextObject };

function AdminProductsScreen({ allText }: Props) {
  const [products, setProducts] = useState<ProductData[]>([]);
  const dispatch = useDispatch();
  const isAdmin = useSelector(
    (state: RootState) => state.configurator.checkRole
  );

  useEffect(() => {
    if (isAdmin !== UserRole.owner && isAdmin !== undefined) {
      window.location.href = "/";
    }
  }, [isAdmin]);

  useEffect(() => {
    checkRoleApi(dispatch);
    // TODO get product data
    setProducts(getProductDataFromDatabase());
  }, []);

  useEffect(() => {
    console.log(products);
  }, [products]);

  const filterProducts = (category: ProductCategory) => {
    return testProductData.filter((prod) => prod.category.includes(category));
  };

  return (
    <>
      <Header allText={allText} />
      {isAdmin === UserRole.owner && (
        <main className="dashboard-page" id="admin-products-page">
          <a href="/dashboard" className="dashboard-goback-arrow">
            <ArrowLeft color="var(--primary-700)" size={32} weight="bold" />
          </a>
          <Title color="var(--primary-700)">Producten & prijzen</Title>
          <div className="dashboard-page-header">
            {/* <QuickFilters type={DashboardPages.products} /> */}
            {/* <AdminSearchBar placeholder="Zoek op offertenr, naam, stad,..." /> */}
          </div>
          <div className="products-menu-list-container">
            <AdminProductsMenu
              productCategory={ProductCategory.boosterPumpsSaltwater}
              categoryName="Opvoerpompen - zeewater"
              products={filterProducts(ProductCategory.boosterPumpsSaltwater)}
            />
            <AdminProductsMenu
              productCategory={ProductCategory.boosterPumpsFreshwater}
              categoryName="Opvoerpompen - zoetwater"
              products={filterProducts(ProductCategory.boosterPumpsFreshwater)}
            />
            <AdminProductsMenu
              productCategory={ProductCategory.LEDSaltwater}
              categoryName="LED verlichting - zeewater"
              products={filterProducts(ProductCategory.LEDSaltwater)}
            />
            <AdminProductsMenu
              productCategory={ProductCategory.LEDFreshwater}
              categoryName="LED verlichting - zoetwater"
              products={filterProducts(ProductCategory.LEDFreshwater)}
            />
            <AdminProductsMenu
              productCategory={ProductCategory.LEDTurtles}
              categoryName="LED verlichting - schildpad"
              products={filterProducts(ProductCategory.LEDTurtles)}
            />
            <AdminProductsMenu
              productCategory={ProductCategory.heatingSaltwater}
              categoryName="Verwarming - zeewater"
              products={filterProducts(ProductCategory.heatingSaltwater)}
            />
            <AdminProductsMenu
              productCategory={ProductCategory.heatingFreshwater}
              categoryName="Verwarming - zoetwater"
              products={filterProducts(ProductCategory.heatingFreshwater)}
            />
            <AdminProductsMenu
              productCategory={ProductCategory.filtersFreshwater}
              categoryName="Filtermateriaal - zoetwater"
              products={filterProducts(ProductCategory.filtersFreshwater)}
            />
            <AdminProductsMenu
              productCategory={ProductCategory.circulationPumpsSaltwater}
              categoryName="Stromingspompen - zeewater"
              products={filterProducts(
                ProductCategory.circulationPumpsSaltwater
              )}
            />
            <AdminProductsMenu
              productCategory={ProductCategory.proteinSkimmersSaltwater}
              categoryName="Eiwitafschuimer - zeewater"
              products={filterProducts(
                ProductCategory.proteinSkimmersSaltwater
              )}
            />
            <AdminProductsMenu
              productCategory={ProductCategory.automaticRefillSystemsSaltwater}
              categoryName="Automatisch bijvulsysteem - zeewater"
              products={filterProducts(
                ProductCategory.automaticRefillSystemsSaltwater
              )}
            />
            <AdminProductsMenu
              productCategory={ProductCategory.co2SystemFreshwater}
              categoryName="CO2-systeem - zoetwater"
              products={filterProducts(ProductCategory.co2SystemFreshwater)}
            />
            <AdminProductsMenu
              productCategory={ProductCategory.interiorSaltwater}
              categoryName="Interieur - zeewater"
              products={filterProducts(ProductCategory.interiorSaltwater)}
            />
            <AdminProductsMenu
              productCategory={ProductCategory.interiorFreshwater}
              categoryName="Interieur - zoetwater"
              products={filterProducts(ProductCategory.interiorFreshwater)}
            />
            <AdminProductsMenu
              productCategory={ProductCategory.interiorTurtles}
              categoryName="Interieur - schildpad"
              products={filterProducts(ProductCategory.interiorTurtles)}
            />
          </div>
        </main>
      )}
    </>
  );
}

export default AdminProductsScreen;
