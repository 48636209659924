import Landing from "./screens/Landing";
import "./styles/shared.css";
import Header from "./components/organisms/Header";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import ErrorScreen from "./screens/ErrorScreen";
import { useDispatch, useSelector } from "react-redux";
import { checkNewAquariumDrawing, RootState } from "./redux/store";
import { getAllTextForLanguage } from "./utils/textRegulator";
import { AllTextObject } from "./@types/language";
import ConfiguratorScreen from "./screens/ConfiguratorScreen";
import { resetAquarium } from "./redux/aquariumSlice";
import AdminLandingScreen from "./screens/AdminLandingScreen";
import AdminOfferteScreen from "./screens/AdminOfferteScreen";
import AdminProductsScreen from "./screens/AdminProductsScreen";
import AdminTranslationsScreen from "./screens/AdminTranslationsScreen";
import AdminUsersScreen from "./screens/AdminUsersScreen";
import OrderSuccesScreen from "./screens/OrderSuccesScreen";
import OrderFailedScreen from "./screens/OrderFailedScreen";
import { transformTestData } from "./data/transformTestData";
import LocalAllText from "./data/text/allTexts.json";
import { setAllText, setAllTextFull } from "./redux/languageSlice";
import { useEffect } from "react";
import allLocalText from "./data/text/allTexts.json";
import {
  updateFilterWidthInfoFromDatabase,
  updateGlassThicknessesFromDatabase,
  updatePricesFromDatabase,
  updateWidthStripsFromDatabase,
} from "./services/api/api";
import { AquariumType } from "./@types/aquarium";
let fetchedLanguageData = false;

function App() {
  const activeLanguage = useSelector(
    (state: RootState) => state.language.activeLanguage
  );
  const aquariumType = useSelector(
    (state: RootState) => state.aquarium.aquariumType
  );
  const allText =
    useSelector((state: RootState) => state.language.allText) ||
    getAllTextForLanguage(allLocalText, "dutch");
  const allTextFull = useSelector(
    (state: RootState) => state.language.allTextFull
  );
  const dispatch = useDispatch();

  if (fetchedLanguageData === false) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", "dbtest.php", true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        try {
          let jsonResponse = JSON.parse(xhr.responseText);
          dispatch(setAllTextFull(jsonResponse));
          dispatch(
            setAllText(getAllTextForLanguage(jsonResponse, activeLanguage))
          );
          fetchedLanguageData = true;
        } catch (error) {
          console.log(error);
        }
      }
    };
    xhr.send();
  }
  const updateJsonFiles = () => {
    updateFilterWidthInfoFromDatabase(dispatch);
    updateGlassThicknessesFromDatabase(
      aquariumType || AquariumType.rimless,
      dispatch
    );
    updatePricesFromDatabase(dispatch);
    updateWidthStripsFromDatabase(dispatch);
  };
  updateJsonFiles();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Landing allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
    {
      path: "/configurator",
      element: <ConfiguratorScreen allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
    {
      path: "/configurator/succes",
      element: <OrderSuccesScreen allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
    {
      path: "/configurator/failed",
      element: <OrderFailedScreen allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
    {
      path: "/dashboard",
      element: <AdminLandingScreen allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
    {
      path: "/dashboard/offertes",
      element: <AdminOfferteScreen allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
    {
      path: "/dashboard/products",
      element: <AdminProductsScreen allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
    {
      path: "/dashboard/translations",
      element: <AdminTranslationsScreen allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
    {
      path: "/dashboard/users",
      element: <AdminUsersScreen allText={allText} />,
      errorElement: <ErrorScreen allText={allText} />,
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
